import {ProductAddToCartContainer, ProductStickyFooterContainer} from "../../../../styled_components/Product";
import {QuantitySwitch} from "../../../core/components/QuantitySwitch";
import {FaShoppingBag} from "react-icons/fa";
import {TextP} from "../../../../styled_components/General";
import {CartItem} from "../../../cart/CartItem";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectProductView} from "../../productSlice";
import {addItemToCart, removeItemFromCart, selectCart, updateItemFromCart} from "../../../cart/cartSlice";
import {isEqual} from "lodash";
import {Cart} from "../../../cart/Cart";
import {Translate} from "../../../intl/translator";
import {canShowAddToCart, isProductCategoryAvailableInSchedule} from "../../menuService";
import {isProductCategoryAvailableInScheduleI} from "../../menuInterface";
import {setSearchPanelStatus} from "../../../core/panelsSlice";
import {selectTable, selectVendor} from "../../../vendor/vendorSlice";

interface ProductStickyFooterI {
    cartItem: CartItem,
    setCartItem: any,
    closeProduct: any
}

export function ProductStickyFooter({cartItem, setCartItem, closeProduct}: ProductStickyFooterI) {
    const productView = useAppSelector(selectProductView);
    const initialCartItem = new CartItem(productView.cartItem ?? cartItem);
    const dispatch = useAppDispatch();
    const cartSliceState = useAppSelector(selectCart);
    const cart = new Cart(cartSliceState);
    const vendor = useAppSelector(selectVendor);
    const table = useAppSelector(selectTable);
    const strings = {
        s_add_to_cart: Translate('add_to_cart'),
        s_update: Translate('update'),
        s_remove: Translate('remove'),
    }

    const addQty = () => {
        setCartItem(cartItem.addItemQty());
    }

    const deductQty = () => {
        if (cartItem.Qty > 0) {
            setCartItem(cartItem.deductItemQty())
        }
    }

    const addToCart = () => {
        const validatedCartItem = cartItem.validate(true);
        setCartItem(cartItem.validate());
        if (validatedCartItem.GroupValidationErrors.length > 0) {
            return;
        }

        if (cartItem.isComplimentary() && cart.findComplimentaryItems().length > 0) {
            return;
        }

        if (cartItem.Qty > 0) {
            const existingInCartItems = cart.findSameCartItems(cartItem);
            if (existingInCartItems.length > 0) {
                let existingItem = new CartItem(existingInCartItems[0]);
                existingItem.Qty += cartItem.Qty;
                dispatch(updateItemFromCart(existingItem));
            } else {
                dispatch(addItemToCart(cartItem));
            }
            dispatch(setSearchPanelStatus(false));
            closeProduct();
        }
    }

    const updateItem = () => {
        if (isItemValidForUpdate() && cartItem.Qty > 0) {
            dispatch(updateItemFromCart(cartItem));
        } else {
            dispatch(removeItemFromCart(cartItem));
        }
        closeProduct();
    }

    const isItemValidForUpdate = (): boolean => {
        return !isEqual(initialCartItem, cartItem) && cartItem.Qty > 0;
    }

    const isProductAvailableInSchedule = (): isProductCategoryAvailableInScheduleI => {
        if (cartItem.Product.menucategoryDetails.length > 0) {
            return isProductCategoryAvailableInSchedule({product: cartItem.Product})
        }
        return {
            isAvailable: true
        };
    }

    return (
        <ProductStickyFooterContainer>
            {isProductAvailableInSchedule().isAvailable && canShowAddToCart({
                    vendorOperationId: vendor.OperationID,
                    vendorOpenStatus: vendor.OpenStatus,
                    table
                }) &&
                <div className="indent flex justify-between items-center w-full mb-3 mt-3">
                    <QuantitySwitch
                        add={addQty}
                        deduct={deductQty}
                        quantity={cartItem.Qty}
                        maxQ={cartItem.getMaxQty()}
                    />

                    {productView.mode === "product" &&
                        <ProductAddToCartContainer
                            onClick={addToCart}
                            disable={(
                                (cartItem.Qty === 0 &&
                                    productView.mode === "product") ||
                                cartItem.validate().GroupValidationErrors.length > 0 ||
                                (cartItem.isComplimentary() && cart.findComplimentaryItems().length > 0)
                            )}>
                            <div className="flex items-center">
                                <FaShoppingBag size={18} color="white" className="mr-2"/>
                                <TextP color="white">{strings.s_add_to_cart}</TextP>
                            </div>
                            <TextP color="white">{cartItem.displayFormattedPrice()}</TextP>
                        </ProductAddToCartContainer>
                    }
                    {productView.mode === "cart" &&
                        <ProductAddToCartContainer onClick={updateItem}>
                            <div className="flex items-center">
                                <FaShoppingBag size={18} color="white" className="mr-2"/>
                                <TextP color="white">{isItemValidForUpdate() ? strings.s_update : strings.s_remove}</TextP>
                            </div>
                            <TextP color="white">{cartItem.displayFormattedPrice()}</TextP>
                        </ProductAddToCartContainer>
                    }

                </div>
            }
            {!isProductAvailableInSchedule().isAvailable &&
                <div className="indent flex justify-center items-center w-full mb-3 mt-3">
                    <ProductAddToCartContainer disable={true} className={"text-white w-full"}>
                        <div className="flex-1 text-center">{isProductAvailableInSchedule().availabilityDetailsMsg}</div>
                    </ProductAddToCartContainer>
                </div>
            }
        </ProductStickyFooterContainer>
    )
}
