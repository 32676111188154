import React, {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {useAppDispatch,useAppSelector} from "../../../app/hooks";
import {loginWaiter, logoutWaiter} from "../userSlice";
import {TextP, Title1} from "../../../styled_components/General";
import {AppButton, AppInputField} from "../../../styled_components/Core";
import {SubmitHandler, useForm} from "react-hook-form";
import {waiterLogin} from "../userRepository";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {clearPastOrders} from "../../cart/ordersSlice";
import {initSession} from "../../session/sessionSlice";
import {selectUser} from "../../user/userSlice";
interface Inputs {
    username: string,
    password: string
}

export function WaiterLoginScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm<Inputs>();
    const [loaded, setLoaded] = useState(false);
    const [authError, setAuthError] = useState("");
    const [pending, setPending] = useState(false);

  

    
    

    const onSubmit: SubmitHandler<Inputs> = data => {
        setAuthError("");
        setPending(true);
        waiterLogin(data.username, data.password).then(waiter => {
            
            dispatch(loginWaiter(waiter));
            if(waiter.ResetAtFirstLogin === '1'){
                navigate("/waiter/reset");
            }else{
                dispatch(initSession());
                dispatch(clearPastOrders());
                localStorage.setItem('load', JSON.stringify({
                    id: waiter.OutletID,
                    table: waiter.TableID
                }))
                setPending(false);
                setLoaded(true);
            }
        }).catch(e => {
            setAuthError("Authentication failed. Please try again.");
            setPending(false);
            console.log(e)
        })
    }


    return (
        <div id="waiter-login-screen">
            <div className="indent mt-40">
                <Title1>User Login</Title1>
            </div>
            {loaded  && <Navigate replace to={"/splash"}/>}
            <div className="indent mt-10">
                <form action="">
                    <TextP>Username</TextP>
                    <AppInputField {...register("username", {
                        required: "Username is required",
                    })}/>
                    {errors.username && <p className='text-red-600'>{errors.username.message}</p>}

                    <TextP className="mt-4">Password</TextP>
                    <AppInputField type="password" {...register("password", {
                        required: "Password is required",
                    })}/>
                    {errors.password && <p className='text-red-600'>{errors.password.message}</p>}

                </form>
                <div className="mt-4 mb-4">
                    {authError && <p className='text-red-600'>{authError}</p>}
                </div>
                <div className="flex justify-center items-center ">
                    <AppButton onClick={handleSubmit(onSubmit)}>
                        {pending ?
                            <AiOutlineLoading3Quarters size={20} className="animate-spin" color="white"/> :
                        "Submit"
                        }

                    </AppButton>
                </div>
            </div>

        </div>
    )
}
