import {ProductStickyHeaderContainer} from "../../../../styled_components/Product";
import {useLayoutEffect, useState} from "react";
import {RoundButtonSmall, Title2} from "../../../../styled_components/General";
import {AiOutlineClose} from "react-icons/ai";

interface ProductStickyHeaderInterface {
    title: string,
    close: any
}
export function ProductStickyHeader({title, close}: ProductStickyHeaderInterface) {
    const [show, setShow] = useState(false);
    useLayoutEffect(() => {
        const container = document.getElementById('product-screen-container');
        function updateStatus() {
            if(container && container?.scrollTop > 150){
                setShow(true);
            }else {
                setShow(false);
            }
        }

        if(container) {
            container.addEventListener('scroll', updateStatus);
        }

        updateStatus();
        return () => window.removeEventListener('scroll', updateStatus);
    })
    return (
        <ProductStickyHeaderContainer show={show}>
            <div className={'px-8 ' + (title.length > 20 ? 'pr-16' : '')}>
                <Title2 color="white">{title}</Title2>

            </div>
            <RoundButtonSmall onClick={close} diameter='35px'
                              style={{position: 'absolute', right: "10px",}}>
                <AiOutlineClose color="white" size={20}/>
            </RoundButtonSmall>

        </ProductStickyHeaderContainer>
    )
}
